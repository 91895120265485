.uhcLogo {
  width: 128px;
  height: 40px;
  margin: 20px 30px;
}

.divider {
  height: 1px;
  background-color: #cccccc;
  width: 100%;
}

.title {
  font-size: 36.48px;
  color: #002677;
  font-weight: 600;
  font-family: "UHC Serif Headline";
  padding-bottom: 18px;
  letter-spacing: -0.5px;
  line-height: 48px;
}

.description {
  font-size: 18px;
  color: #323334;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 24px;
}

.handHoldingCardTabletImg {
  width: auto;
}

.signIn,
.registerNow {
  margin-right: 12px;
}

.signInTitle,
.registerTitle {
  font-size: 20.25px;
  color: #002677;
  font-weight: 700;
  padding-bottom: 9px;
}

.handHoldingCardImg {
  width: auto;
  padding-left: 12%;
  margin-top: 4px;
}

.footer {
  background-color: #00bed5;
  height: 250px;
  width: 100%;
}

/* Responsiveness */
/* Mobile */
@media (min-width: 280px) and (max-width: 767px) {
  .footer {
    margin-top: 70px;
  }

  .uhcLogo {
    margin: 10px 7.5%;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 819px) {
  .footer {
    margin-top: 130px;
  }
}

@media (min-width: 820px) and (max-width: 983px) {
  .footer {
    margin-top: 280px;
  }
}

@media (min-width: 984px) and (max-width: 1247px) {
  .footer {
    margin-top: 450px;
  }
}

@media (min-width: 768px) and (max-width: 1247px) {
  .footer {
    height: 256px;
  }
}

/* Desktop */
@media (min-width: 1248px) {
  .divider {
    display: none;
  }
}
