.Loader {
	display: inline-block!important;
	width: 100% !important;
	
}
.Loader::after {
	content: " "!important;
	display: block!important;
	position: relative!important;
	top: 45%!important;
	left: 45%!important;
	z-index: 2!important;
	width: 100px!important;
	height: 100px!important;
	margin: 8px!important;
	border-radius: 50%!important;
	border: 6px solid #3826db!important;
	border-color: #3826db transparent #3826db transparent!important;
	animation: lds-dual-ring 1.2s linear infinite!important;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}