.screenShot20211020At105 {
  position: absolute;
  width: 122.01%;
  right: -22.01%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: 171px;
  object-fit: cover;
}

.tempLink
{
text-align: center;
margin-top:  450px;
}

button:focus
{
  outline: 5px solid #196ecf !important;
  color: #196ecf;
}

 a:focus {
  outline: 5px solid #709e0e !important;
  border: 1px solid;
}

.margin0 {
  margin: 0px;
}
.h1FntSize {
  font-size: 36.49px !important;
}
.h2FntSize {
  font-size: 20.25px !important;
}
.desktopGlobalNavMain,
.rectangleCopy15 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 69px;
}
.rectangleCopy15 {
  height: 113.79%;
  width: 100.14%;
  top: -0.12%;
  right: -0.07%;
  bottom: -13.67%;
  left: -0.07%;
  background-color: #002677;
  backdrop-filter: blur(27.18px);
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
}
.unlockMoreFromYourPlanBy {
  position: absolute;
  height: 10.48%;
  width: 35.21%;
  top: 12.38%;
  right: 58.82%;
  bottom: 77.14%;
  left: 4.97%;
  line-height: 44px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.unionIcon {
  position: relative;
  width: 29px;
  height: 51px;
}
.benefitsHeaderDiv,
.groupDiv {
  position: absolute;
  left: 0;
  width: 100%;
  height: 840px;
}
.groupDiv {
  top: 0;
  overflow: hidden;
}
.benefitsHeaderDiv {
  top: 69px;
  font-size: 36.49px;
  font-family: UHC Serif Headline;
}
.letsGetYouSetUp {
  position: absolute;
  width: 39.24%;
  top: calc(51% - 249px);
  right: 54.93%;
  left: 4.83%;
  line-height: 24px;
  display: inline-block;
  height: 83px;
}
.textB {
  position: relative;
  /* line-height: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
}
.signInToActivateYourCard {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  width: 340px;
}
.frameDiv1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
}
.mediumButtonDiv {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}
.buttonDiv {
  position: absolute;
  top: calc(50% - 20.79px);
  left: calc(50% - 68.5px);
  border-radius: 24px;
  border: 2px solid #002677;
  box-sizing: border-box;
  height: 42px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 8px 44px;
  align-items: center;
  justify-content: center;
}
.frameDiv2 {
  position: relative;
  width: 340px;
  height: 60px;
  flex-shrink: 0;
  overflow: hidden;
  font-size: 16px;
  color: #002677;
}
.frameDiv,
.recDiv {
  display: flex;
  flex-direction: column;
}
.frameDiv {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.recDiv {
  position: absolute;
  top: 477px;
  left: 86px;
  border-radius: 8px;
  background-color: #e5f8fb;
  box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
  width: 27%;
  height: 214px;
  padding: 34px 24px 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #333;
}
.mediumButtonDiv1 {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-family: “UHC sans”, Arial,sans-serif;
  color: #002677;
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: calc(50% - 20.79px);
  left: calc(50% - 98.5px);
}
.frameDiv5 {
  position: relative;
  width: 340px;
  height: 60px;
  flex-shrink: 0;
  overflow: hidden;
}
.recDiv1 {
  position: absolute;
  top: 477px;
  left: 35%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
  width: 27%;
  height: 214px;
  display: flex;
  flex-direction: column;
  padding: 34px 24px 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #333;
}
.iLLUSTRATIONIcon {
  position: absolute;
  height: 469px;
  width: 824px;
  top: 4.81%;
  right: 0;
  bottom: 61.89%;
  left: 54.86%;
  max-width: 100%;
  max-height: 100%;
}
.activateuhccomDiv {
  position: relative;
  background-color: #002677;
  width: 100%;
  height: 1042px;
  overflow: hidden;
  text-align: left;
  font-size: 20.25px;
  color: #fff;
  font-family: “UHC sans”, Arial,sans-serif;
}
.activateuhccomFooterDiv {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: 20.25px;
  color: #fff;
  font-family: “UHC sans”, Arial,sans-serif;
}

.createAccbtn {
  /* button */

background-color:  #fff;
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 44px;
gap: 4px;

position: absolute;
width: 196px;
height: 42px;
left: calc(50% - 196px/2 - 0.5px);
top: calc(50% - 42px/2 + 0.21px);

/* Icon/Main Blue */

border: 2px solid #002677;
border-radius: 24px;
cursor: pointer;
}

.disabledCreateAccbtn {
background-color:  #fff;
box-sizing: border-box;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 44px;
gap: 4px;
position: absolute;
width: 196px;
height: 42px;
left: calc(50% - 196px/2 - 0.5px);
top: calc(50% - 42px/2 + 0.21px);
border-radius: 24px;
}

.signInBtn {
  /* button */
background-color:  #e5f8fb;

box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 44px;
gap: 4px;

position: absolute;
width: 136px;
height: 42px;
left: calc(50% - 136px/2 - 0.5px);
top: calc(50% - 42px/2 + 0.21px);

/* Icon/Main Blue */

border: 2px solid #002677;
border-radius: 24px;
cursor: pointer;
}

.disabledSignInBtn {
background-color:  #e5f8fb;
box-sizing: border-box;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 44px;
gap: 4px;
position: absolute;
width: 136px;
height: 42px;
left: calc(50% - 136px/2 - 0.5px);
top: calc(50% - 42px/2 + 0.21px);
border-radius: 24px;
}

.activateHeader {
position: absolute;
width: 100%;
height: 69px;
left: 0px;
top: 0px;
background: #002677;
}

.uhcLogo {
position: absolute;
width: 156px;
height: 48px;
left: 84px;
top: 10px;
}




/* For handling larger resolutions from 1826 to 3495 (illustration and letsGetYouSetUp Text)*/

@media (min-width: 2774px) and (max-width: 3495px) {
  .iLLUSTRATIONIcon {
    position: absolute;
    height: 469px;
    width: 824px;
    top: 4.81%;
    right: 0;
    bottom: 61.89%;
    left: 76.86%;
    max-width: 100%;
    max-height: 100%;
  }

  .activateuhccomDiv {
    position: relative;
    background-color: #002677;
    width: 100%;
    height: 1300px;
    overflow: hidden;
    text-align: left;
    font-size: 20.25px;
    color: #fff;
    font-family: “UHC sans”, Arial,sans-serif;
  }

  .letsGetYouSetUp {
    position: absolute;
    width: 39.24%;
    top: calc(40% - 249px);
    right: 54.93%;
    left: 4.83%;
    line-height: 24px;
    display: inline-block;
    height: 83px;
  }
}



@media (min-width: 1826px) and (max-width: 2774px) {

  .iLLUSTRATIONIcon {
    position: absolute;
    height: 469px;
    width: 824px;
    top: 4.81%;
    right: 0;
    bottom: 61.89%;
    left: 69.86%;
    max-width: 100%;
    max-height: 100%;
  }
  
  .activateuhccomDiv {
    position: relative;
    background-color: #002677;
    width: 100%;
    height: 1300px;
    overflow: hidden;
    text-align: left;
    font-size: 20.25px;
    color: #fff;
    font-family: “UHC sans”, Arial,sans-serif;
  }

  .letsGetYouSetUp {
    position: absolute;
    width: 39.24%;
    top: calc(40% - 249px);
    right: 54.93%;
    left: 4.83%;
    line-height: 24px;
    display: inline-block;
    height: 83px;
  }

}

@media (min-width: 699px) and (max-width: 1640px) {
.letsGetYouSetUp,.unlockMoreFromYourPlanBy {
  width: 42%;
}
}

@media (min-width: 1180px) and (max-width: 1640px) {
  .letsGetYouSetUp {
    top: calc(53% - 249px);
  }
}

/* Tablet View (handling illustration image and two sign in and create account divs)*/
@media (min-width: 699px) and (max-width: 1179px) {
      

  .letsGetYouSetUp {
    position: absolute;
    width: 39.24%;
    top: calc(55% - 249px);
    right: 54.93%;
    left: 4.83%;
    line-height: 24px;
    display: inline-block;
    height: 83px;
  }

  .recDiv1 {
    position: absolute;
    top: 540px;
    left: 51%;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
    width: 45%;
    height: 214px;
    display: flex;
    flex-direction: column;
    padding: 34px 24px 24px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333;
  }

  .recDiv {
    position: absolute;
    top: 540px;
    left: 4.83%;
    border-radius: 8px;
    background-color: #e5f8fb;
    box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
    width: 45%;
    height: 214px;
    padding: 34px 24px 24px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #333;
  }

  .iLLUSTRATIONIcon {
    position: absolute;
    height: 300px;
    width: 824px;
    top: 12.81%;
    right: 0;
    bottom: 61.89%;
    left: 48.86%;
    max-width: 70%;
    max-height: 100%;
  }

}


/* for Mobile View*/
@media (min-width: 280px) and (max-width: 699px) {
  .uhcLogo {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 18px;
    top: 10px;
    }
  .iLLUSTRATIONIcon{
    display: none;
  }
  .icArrowBackIcon {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .mediumLinkB {
    position: relative;
    line-height: 20px;
    display: none;
  }
  .linkmediumDiv {
    position: absolute;
    top: 75px;
    left: -10px;
    border-radius: 24px;
    width: 116px;
    height: 42px;
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .rectangleDiv {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #002677;
    width: 375px;
    height: 739px;
  }
  .letsGetYouSetUp,
  .unlockMoreFromYourPlanBy {
    top: 2%;
    position: absolute;
    width: 91.47%;
    right: 4.27%;
    left: 4.27%;
    color: #fff;
    text-align: left;
  }
  .unlockMoreFromYourPlanBy {
    /* top: 110px; */
    font-size: 28.83px;
    line-height: 36px;
    font-weight: 600;
    font-family: UHC Serif Headline;
    display: flex;
    align-items: center;
  }
  .letsGetYouSetUp {
    position: absolute;
    height: 87px;
    left: 4.27%;
    right: 4.27%;
    top: 200px;
    
    /* Mobile/Heading 6 */
    
    font-family: “UHC sans”, Arial,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */
    
    
    color: #FFFFFF;
  }
 
  .signInToActivateYourCard,
  .textB {
    position: relative;
    /* line-height: 5px; */
    width: 340px;
  }
  .textB {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .signInToActivateYourCard {
    font-weight: 500;
    display: inline-block;
  }
  .frameDiv1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .mediumButtonDiv {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
  }
  .buttonDiv {
    position: absolute;
    top: calc(50% - 20.79px);
    left: calc(50% - 68.5px);
    border-radius: 24px;
    border: 2px solid #002677;
    box-sizing: border-box;
    height: 42px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 8px 44px;
    align-items: center;
    justify-content: center;
  }
  .frameDiv2 {
    position: relative;
    width: 340px;
    height: 60px;
    flex-shrink: 0;
    overflow: hidden;
    color: #002677;
  }
  .frameDiv,
  .recDiv {
    flex-direction: column;
  }
  .frameDiv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  .recDiv {
    top: 330px;
    left: 10px;
    border-radius: 8px;
    background-color: #e5f8fb;
    box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
    width: 95%;
    height: 18%;
    padding: 34px 24px 24px;
    color: #333;
  }
  .buttonDiv1,
  .recDiv,
  .recDiv1 {
    position: absolute;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .buttonDiv1 {
    top: calc(50% - 20.79px);
    left: calc(50% - 98.5px);
    border-radius: 24px;
    border: 2px solid #002677;
    height: 42px;
    overflow: hidden;
    flex-direction: row;
    padding: 8px 44px;
  }
  .recDiv1 {
    top: 52%;
    left: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
    width: 95%;
    height: 18%;
    flex-direction: column;
    padding: 34px 24px 24px;
    color: #333;
  }
  .needHelpB {
    position: absolute;
    top: 780px;
    left: 135px;
    font-size: 20.25px;
    line-height: 24px;
    display: inline-block;
    color: #000;
  }
  .span {
    text-decoration: underline;
    color: #196ecf;
  }
  .wereHereForContainer {
    line-break: anywhere;
    width: 100%;
  }
  .wereHereForYouCallUsTo {
    position: absolute;
    top: 818px;
    left: 17px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 341px;
    color: #000;
  }
  .smallLinkB {
    position: relative;
    line-height: 16px;
    display: inline-block;
  }
  .icChevronRightIcon {
    position: relative;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
  .linksmallDiv,
  .linksmallDiv1 {
    position: absolute;
    top: 874px;
    left: 143px;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 12.64px;
  }
  .linksmallDiv1 {
    top: 916px;
    left: 19px;
  }
  .cardActivationMobile {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 739px;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    color: #196ecf;
    font-family: “UHC sans”, Arial,sans-serif;
  }

  .h1FntSize {
    font-size: 28.83px !important;
  }
  .h2FntSize {
    font-size: 16px !important;
  }
  
}

